/* eslint @typescript-eslint/no-explicit-any: 0 */
import {
  ActiveDealFilters,
  DealFiltersAction,
  DealFiltersState,
  DealFiltersTickTrait,
} from './types'
import { isFilterAvailable } from './util/updateFilters'
import uniqueElements from '@g4g/utils/src/uniqueElements'
import { DealFiltersSortBy } from './types'

/**
 * This will get encoded in the URL via
 * new URLSearchParams or Next Router.replace
 * even though comma might be valid, it's just
 * easier & safer if we want these subdelimiters
 * @see https://stackoverflow.com/questions/2366260/whats-valid-and-whats-not-in-a-uri-query
 */
const QUERY_PARAM_SUB_DELIMITER = ','

/**
 * Checks wheterever the query param key is
 * a TickTrait, returns the trait if true.
 *
 * @param key query param key
 * @returns trait if found
 */
export const getTickTrait = (key: string) => {
  return (['category', 'resource'] as DealFiltersTickTrait[]).find((t) => t === key)
}

/**
 * Deletes all the filter related query params,
 * skips the other ones.
 *
 * @param query current query param filters
 */
export const resetFilterQueryParams = (query: any) => {
  for (const prop of Object.getOwnPropertyNames(query)) {
    if (getTickTrait(prop)) {
      delete query[prop]
    }
  }
}

/**
 * Checks whether there is anything filter
 * related inside the query params.
 *
 * @param query
 */
export const areQueryFiltersEmpty = (query: any) => {
  for (const prop of Object.getOwnPropertyNames(query)) {
    if (getTickTrait(prop)) {
      return false
    }
  }

  return true
}

/**
 * Get this { tickFilters: ["gems", "crypton"], trait: 'resource' }
 * from key: "resource", value: "gems,crypton", undefined if something
 * invalid.
 *
 * @param key Query param key
 * @param value Query param value
 * @returns All the Tick filters
 */
export const parseTickFilters = (key: string, value: string) => {
  const trait = getTickTrait(key)

  if (!trait) {
    return
  }

  // category=a,b,c format
  // Look for words separated by comma
  const regex = new RegExp(`[^${QUERY_PARAM_SUB_DELIMITER}\\s?]+`, 'g')
  const filters = value.match(regex)

  if (!filters || filters.length === 0) {
    return
  }

  return {
    tickFilters: uniqueElements(filters),
    trait,
  } as { tickFilters: string[]; trait: DealFiltersTickTrait }
}

/**
 * Get price (number) from
 * key: "priceMin", value: "1", etc. if valid.
 *
 * @param key Query param key
 * @param value Query param value
 * @returns price
 */
export const parsePriceFilters = (key: string, value: string) => {
  let price
  if (key === 'priceMin' || key === 'priceMax') {
    price = parseInt(value)
  }

  if (price && price > -1) {
    return price
  }
}

/**
 * Add a new filter query param.
 *
 * @param action Dispatch action
 * @param query Current query params
 */
export const naiveUpdateQueryFilters = (action: DealFiltersAction, query: any) => {
  switch (action.type) {
    case 'update-tick-trait':
      query[action.trait] = action.name
      return
    case 'update-min-price':
      query['priceMin'] = action.priceMin
      return
    case 'update-max-price':
      query['priceMax'] = action.priceMax
      return
    default:
      break
  }
}

/**
 * i.e. true if query is {} or
 * {
 *   resource: 'gems',
 *   <-- no category yet
 * }
 *
 * @param action Dispatch action
 * @param query Current query params
 * @returns
 */
export const shouldNaiveUpdateQueryFilters = (action: DealFiltersAction, query: any) => {
  return (
    areQueryFiltersEmpty(query) ||
    (action.type === 'update-tick-trait' &&
      !Object.prototype.hasOwnProperty.call(query, action.trait)) ||
    (action.type === 'update-max-price' &&
      !Object.prototype.hasOwnProperty.call(query, 'priceMax')) ||
    (action.type === 'update-min-price' && !Object.prototype.hasOwnProperty.call(query, 'priceMin'))
  )
}

/**
 * Returns an updated query param object based
 * on the new DealFilters state.
 * turns resource=gems into resource=gems,crypton
 * etc.
 *
 * @param action Reducer action
 * @param query Current query params
 * @returns Updated query params
 */
export const updateQueryParamFilters = (action: DealFiltersAction, query: any) => {
  if (action.type === 'reset-all-filters') {
    resetFilterQueryParams(query)
    return
  }

  if (shouldNaiveUpdateQueryFilters(action, query)) {
    naiveUpdateQueryFilters(action, query)
    return
  }

  for (const [key, value] of Object.entries(query)) {
    if (typeof value !== 'string') {
      continue
    }

    if (action.type === 'update-tick-trait') {
      const tickResult = parseTickFilters(key, value)

      if (!tickResult || tickResult.trait !== action.trait) {
        continue
      }

      const { tickFilters, trait } = tickResult
      let updatedFilters = []

      if (action.active) {
        // Already enabled
        if (tickFilters.includes(action.name)) {
          continue
        }
        updatedFilters = [...tickFilters, action.name]
      } else {
        updatedFilters = tickFilters.filter((filter) => filter !== action.name)
      }

      if (updatedFilters.length === 0) {
        delete query[trait]
        return
      }
      query[trait] = updatedFilters.join(QUERY_PARAM_SUB_DELIMITER)
    }

    if (action.type === 'update-max-price') {
      query['priceMax'] = action.priceMax
    }

    if (action.type === 'update-min-price') {
      query['priceMin'] = action.priceMin
    }

    if (action.type === 'update-sort') {
      query['sort'] = action.sortBy
    }
  }
}

/**
 * Parse the current query params and
 * construct a DealFilters state if any valid
 * filters found.
 *
 * @param query Current query param object
 * @returns Loaded DealFilters state
 */
export const loadStateFromQuery = (query: any): DealFiltersState => {
  const loadedState: ActiveDealFilters = {
    categories: new Set<string>(),
    resources: new Set<string>(),
    priceMin: 0,
    priceMax: 100,
    sortBy: 'featured',
  }

  for (const [key, value] of Object.entries(query)) {
    if (typeof value !== 'string') {
      continue
    }

    const tickResult = parseTickFilters(key, value)

    if (tickResult) {
      const { tickFilters, trait } = tickResult

      tickFilters.forEach((filter) => {
        if (!isFilterAvailable(filter)) {
          return
        }

        switch (trait) {
          case 'category':
            loadedState.categories.add(filter)
            break
          case 'resource':
            loadedState.resources.add(filter)
            break
          default:
            break
        }
      })
    }

    const price = parsePriceFilters(key, value)

    if (price && key === 'priceMin') {
      loadedState.priceMin = price
    }
    if (price && key === 'priceMax') {
      loadedState.priceMax = price
    }

    if (key === 'sort') {
      loadedState.sortBy = value as DealFiltersSortBy
    }
  }

  return loadedState
}

/**
 * Updates the URL withthout triggering a top level
 * rerender, an alternative to next/router replace.
 *
 * @see https://github.com/vercel/next.js/discussions/18072
 */
export const nativeFilterRouterReplace = (path: string, updatedQuery: any) => {
  const queryString = new URLSearchParams(updatedQuery).toString()
  const newUrl = `${path}${queryString ? `?${queryString}` : ''}`

  window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl)
}
