/**
 * @returns the provided value casted as an array if it's not one.
 * @example
 * castArray('foo'); // ['foo']
 * castArray([1]); // [1]
 * @see https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/castArray.md
 */
const castArray = <T>(val: T | T[]): T[] => (Array.isArray(val) ? (val as any) : [val])

export default castArray
