import type { FC } from 'react'
import type { GetShopItems_getShopItems_lootRewardPackage_lootRewards } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import StandardLootReward from '../rewards/StandardLootReward'
import SmallLootReward from '../rewards/SmallLootReward'
import LazyBoundary from '@g4g/ui/src/components/LazyBoundary'
import { classNames } from '@g4g/utils/src/react/class-names'
import { isSkinLootImg } from '@g4g/utils/src/gameDataUtils'
import LootRewardRowBadge from './badges/LootRewardRowBadge'
import { RewardType } from '@g4g/graphql/src/shop/__generated__/globalTypes'

const MAX_LARGE_IN_A_ROW = 3
const HIDE_VIP_ROW_BADGE_AT_N_ITEMS = 10

const BonusRewardsRow: FC<{
  responsive?: boolean
  stretched?: boolean
  vipExpReward?: boolean
  rewards: GetShopItems_getShopItems_lootRewardPackage_lootRewards[]
}> = ({ vipExpReward, rewards, responsive, stretched }) => {
  const bonusRewards = vipExpReward
    ? rewards.filter((r) => r.rewardType !== RewardType.VipExp)
    : rewards

  return (
    <div
      className={classNames(
        'flex w-full pb-1 mt-1',
        vipExpReward && !responsive && 'h-7',
        vipExpReward && responsive && 'h-7 lg:h-11'
      )}
    >
      {vipExpReward && (
        <div className="absolute bottom-0 left-0">
          <LootRewardRowBadge
            gradient
            lootRewards={rewards}
            responsive={responsive}
            stretched={stretched}
            rewardType={RewardType.VipExp}
          />
        </div>
      )}
      {bonusRewards.length > 0 && (
        <div className="flex flex-wrap mx-auto">
          <div className="mr-1 text-white font-poet">+</div>
          {bonusRewards.map((loot, i) => (
            <SmallLootReward key={`loot-${i}`} loot={loot} responsive={responsive} withBackground />
          ))}
        </div>
      )}
    </div>
  )
}

const SmallRewardsRow: FC<{
  lootRewards: GetShopItems_getShopItems_lootRewardPackage_lootRewards[]
  responsive?: boolean
  stretched?: boolean
}> = ({ lootRewards, responsive, stretched }) => {
  const smallRewards = lootRewards
    .filter((loot) => !isSkinLootImg(loot.customImg))
    .slice(MAX_LARGE_IN_A_ROW, lootRewards.length)

  // Some bundles have too many rewards to fit the
  // bottom left vip badge, so mix the vip reward into the
  // other middle +rewards
  if (lootRewards.length >= HIDE_VIP_ROW_BADGE_AT_N_ITEMS) {
    return <BonusRewardsRow stretched={stretched} rewards={smallRewards} responsive={responsive} />
  }

  return (
    <BonusRewardsRow
      vipExpReward
      rewards={smallRewards}
      responsive={responsive}
      stretched={stretched}
    />
  )
}

const StandardRewardsRow: FC<{
  lootRewards: GetShopItems_getShopItems_lootRewardPackage_lootRewards[]
  responsive?: boolean
}> = ({ lootRewards, responsive }) => (
  <div className="z-10 flex w-full mb-1">
    <div className="flex mx-auto 2xl:space-x-3">
      {lootRewards
        .slice(0, MAX_LARGE_IN_A_ROW)
        // Don't show the skins on deal thumbnails
        .filter((loot) => !isSkinLootImg(loot.customImg))
        .map((loot, i) => (
          <StandardLootReward
            key={`loot-${i}`}
            index={i}
            loot={loot}
            shine={true}
            responsive={responsive}
          />
        ))}
    </div>
  </div>
)

const DealThumbnailLoot: FC<{
  lootRewards: GetShopItems_getShopItems_lootRewardPackage_lootRewards[]
  responsive?: boolean
  stretched?: boolean
}> = ({ lootRewards, responsive, stretched }) => (
  <LazyBoundary>
    {lootRewards.length > 0 && (
      <div
        className={classNames('flex flex-col', lootRewards.length <= MAX_LARGE_IN_A_ROW && 'mb-1')}
      >
        <StandardRewardsRow lootRewards={lootRewards} responsive={responsive} />
        {lootRewards.length > MAX_LARGE_IN_A_ROW && (
          <SmallRewardsRow
            lootRewards={lootRewards}
            responsive={responsive}
            stretched={stretched}
          />
        )}
      </div>
    )}
  </LazyBoundary>
)

export default DealThumbnailLoot
