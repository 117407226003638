import type { FC } from 'react'
import { CheckIcon, ShoppingCartIcon } from '@heroicons/react/20/solid'
import { classNames } from '@g4g/utils/src/react/class-names'

const AddedToCartIcon: FC<{ iconsClassNames?: string }> = ({ iconsClassNames }) => (
  <div className="flex">
    <CheckIcon className={classNames(iconsClassNames ?? 'w-6 h-6 ', 'text-shopStdGreen')} />
    <ShoppingCartIcon className={classNames(iconsClassNames ?? 'w-6 h-6', 'text-shopStdGreen')} />
  </div>
)

export default AddedToCartIcon
