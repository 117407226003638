/**
 * Turns milliseconds into other time units.
 */
export const calculateDaysHoursMinsSecs = (timeMillis: number) => {
  const sec = 1000
  const min = sec * 60
  const hour = min * 60
  const day = hour * 24
  return {
    days: Math.floor(timeMillis / day),
    hours: Math.floor(timeMillis / hour),
    mins: Math.floor(timeMillis / min),
    secs: Math.floor(timeMillis / sec),
  }
}

export function getNextWeeklyReset(): Date {
  const now = new Date()
  const day = now.getUTCDay()
  const normalized = (day + 2) % 7
  const daysFwd = 7 - normalized
  const nextFriday = new Date(now.getTime() + daysFwd * 24 * 60 * 60 * 1000)
  nextFriday.setUTCHours(0)
  nextFriday.setUTCMinutes(0)
  nextFriday.setUTCSeconds(0)
  nextFriday.setUTCMilliseconds(0)
  return nextFriday
}

// https://modernjavascript.blogspot.com/2013/09/incrementing-date-to-next-friday.html
export function getMillisRemainingToWeeklyReset(): number {
  return getNextWeeklyReset().getTime() - new Date().getTime()
}

export function getFirstDayOfNextMonth(): Date {
  const date = new Date()

  return new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 1)
}

export function getMillisRemainingToMonthlyReset(): number {
  return getFirstDayOfNextMonth().getTime() - new Date().getTime()
}
