import type { FC } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { CheckIcon } from '@heroicons/react/20/solid'
import { TickFilter } from '@g4g/utils/src/types'
import { useDealFilters, useDealFiltersOpsDispatch } from '../deals/DealFiltersProvider'
import { DealFiltersTickTrait } from '../deals/provider/types'
import { classNames } from '@g4g/utils/src/react/class-names'

const CheckboxFilter: FC<{
  name: string
  id: string
  icon?: string
  active: boolean
  trait: DealFiltersTickTrait
}> = ({ name, id, icon, active, trait }) => {
  const dispatch = useDealFiltersOpsDispatch()

  return (
    <button
      role="checkbox"
      aria-checked={active}
      data-cy="shop-side-filter-checkbox"
      data-cy-spec={id}
      className="flex items-center w-full mt-1 space-x-2 group h-11 lg:h-auto"
      onClick={() => {
        dispatch({ type: 'update-tick-trait', trait, name: id, active: !active })
      }}
    >
      <div className="flex space-x-1 truncate grow">
        {icon && <Image src={icon} height={25} width={25} alt={name} />}
        <p
          className={classNames(
            'font-poppins truncate lg:group-hover:text-white',
            active ? 'text-white' : 'text-gray-400'
          )}
        >
          {name}
        </p>
      </div>
      <div
        className={classNames(
          'min-w-5 min-h-5 h-5 w-5 rounded group-hover:ring-white',
          'flex items-center justify-center ring-gray-400',
          active ? 'ring-2 ring-white' : 'ring-1'
        )}
      >
        {active && (
          <div className="flex w-5 h-5 text-green-500">
            <CheckIcon />
          </div>
        )}
      </div>
    </button>
  )
}

const CheckboxFilters: FC<{ filters: TickFilter[]; trait: DealFiltersTickTrait }> = ({
  trait,
  filters,
}) => {
  const { categories, resources } = useDealFilters()

  return (
    <>
      {filters.map(({ name, id, iconPath }) => (
        <CheckboxFilter
          key={`checkbox-${id}`}
          trait={trait}
          name={name}
          id={id}
          icon={iconPath}
          active={categories.has(id) || resources.has(id)}
        />
      ))}
    </>
  )
}

const LinkFilters: FC<{ filters: TickFilter[]; trait: DealFiltersTickTrait }> = ({
  filters,
  trait,
}) => (
  <>
    {filters.map(({ name, id, iconPath }) => (
      <div className="flex" key={`link-${id}`}>
        <Link
          href={`/deals?${trait}=${id}`}
          data-cy="shop-side-filter-link"
          data-cy-spec={`shop-side-filter-link-${id}`}
          className="flex space-x-1"
        >
          {iconPath && <Image src={iconPath} height={25} width={25} alt={name} />}
          <p className="text-gray-400 font-poppins hover:text-white">{name}</p>
        </Link>
      </div>
    ))}
  </>
)

const SideFilter: React.FC<{
  filters: TickFilter[]
  isLink?: boolean
  trait: DealFiltersTickTrait
}> = ({ filters, isLink, trait }) => {
  return (
    <>
      <h3 className="mb-2 text-xl font-medium text-white sr-only font-poppins lg:not-sr-only">
        {trait === 'category' ? 'Deals' : 'Resources'}
      </h3>
      {isLink ? (
        <LinkFilters filters={filters} trait={trait} />
      ) : (
        <CheckboxFilters trait={trait} filters={filters} />
      )}
    </>
  )
}

export default SideFilter
