import type { FC, HTMLAttributes } from 'react'
import Link, { LinkProps } from 'next/link'

const ALink: FC<LinkProps & HTMLAttributes<HTMLAnchorElement>> = ({ href, children, ...rest }) => (
  <Link href={href} {...rest}>
    {children}
  </Link>
)

export default ALink
