import type { FC, MouseEventHandler, ReactNode } from 'react'
import { classNames } from '@g4g/utils/src/react/class-names'
import ALink from './ALink'

// TODO: Fix remove interface & extend with Native HtmlButton
export interface IMinimalistButtonProps {
  mainColor: string
  backgroundColor?: string
  textClassSize?: string
  font?: string
  icon?: React.ReactNode | JSX.Element
  href?: string
  /** Full width. */
  full?: boolean
  as?: 'link' | 'a' | 'button'
  buttonText?: ReactNode
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  /** Flip the colors, filling background instead of outlining. */
  fill?: boolean
  className?: string
  target?: string
  ring?: string
  textColor?: string
}

/* HACK: we're trying to transition away from the `props={{...stuff}}` thing, into
 * the usual ```prop={something} anotherProp={123}``` thing.
 */
export const MinimalistButton: FC<
  { props?: IMinimalistButtonProps; 'data-cy'?: string } & Partial<IMinimalistButtonProps>
> = ({ props: _props, 'data-cy': dataCy, textColor = 'black', ...rest }) => {
  const props = {
    ..._props,
    ...rest,
  }
  // The `ALink` thing breaks typing on this (href is `string | undefined` which errors).
  // So cast the whole thing.
  const CustomButton = (props.as === 'link'
    ? ALink
    : `${props.href ? 'a' : 'button'}`) as unknown as keyof JSX.IntrinsicElements

  return (
    <CustomButton
      {...{
        ...(props.target && { target: props.target, rel: 'noreferrer' }),
        ...(props.href && { href: props.href }),
        ...(props.onClick && { onClick: (e: any) => props.onClick && props.onClick(e) }),
      }}
      data-cy={dataCy}
      disabled={props.disabled}
      className={classNames(
        props.className ?? 'py-1 sm:py-1.5',
        props.ring ?? 'border',
        'flex filter z-20 px-3 sm:px-4',
        'rounded-md hover:brightness-125',
        'disabled:brightness-50 disabled:hover:brightness-50',
        props.full && 'w-full justify-center items-center'
      )}
      // TODO: Not good
      style={{
        borderColor: props.ring ? undefined : props.mainColor,
        backgroundColor: props.fill ? props.mainColor : undefined,
      }}
    >
      <span
        className={classNames(
          'flex items-center font-medium',
          props.font ? props.font : 'font-poppins',
          props.textClassSize ?? 'text-sm'
        )}
        // TODO: Not good
        style={{ color: props.fill ? textColor : props.mainColor }}
      >
        {props.icon}
        {props.buttonText}
      </span>
    </CustomButton>
  )
}
