import type { GTM_ECommerce_BaseParams } from './types'
import { logErrorMessage } from '@g4g/utils/src/react/error'
import { gtm_shop_item_ecommerce_event } from '.'
import toTitleCase from '@g4g/utils/src/toTitleCase'

interface GTM_ECommerce_AddToCartParams extends GTM_ECommerce_BaseParams {
  // Whether the buy button was tapped
  buyNow?: boolean
  pathname: string
}

enum GTM_ECommerce_AddToCartOrigin {
  Unknown = 'unknown',
  // Deals Detail add to cart button
  DetailAddToCartBtn = 'detail_add_to_cart_btn',
  // Deals Detail buy now button
  DetailBuyNowBtn = 'detail_buy_now_btn',
  // Homepage showcased deal add to cart button
  ShowcaseAddToCartBtn = 'showcase_add_to_cart_btn',
  // Homepage showcased deal buy now
  ShowcaseBuyNowBtn = 'showcase_buy_now_btn',
}

const resolveAddToCartOrigin = (
  pathname: string,
  buyNow?: boolean
): GTM_ECommerce_AddToCartOrigin => {
  switch (pathname) {
    case '/deals/[kebabName]':
      return buyNow
        ? GTM_ECommerce_AddToCartOrigin.DetailBuyNowBtn
        : GTM_ECommerce_AddToCartOrigin.DetailAddToCartBtn
    case '/':
      return buyNow
        ? GTM_ECommerce_AddToCartOrigin.ShowcaseBuyNowBtn
        : GTM_ECommerce_AddToCartOrigin.ShowcaseAddToCartBtn
    default:
      logErrorMessage('Unknown gtm add to cart origin, path:' + pathname)
      return GTM_ECommerce_AddToCartOrigin.Unknown
  }
}

/**
 * Sends a GA4 recommended add_to_cart event.
 *
 * Includes cart updates, e.g.
 * add 1x Gem Boost -> event triggered, qty 1
 * update cart with +2 Gem Boosts -> additional event triggered, qty 3
 *
 * @see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_or_remove_an_item_from_a_shopping_cart
 */
const gtm_add_to_cart = ({ items, buyNow, pathname }: GTM_ECommerce_AddToCartParams) => {
  gtm_shop_item_ecommerce_event('add_to_cart', items, {
    item: () => {
      // Which AddToCard button type was tapped,
      // to distinguish between <add to cart> and <buy now>
      const item_list_id = resolveAddToCartOrigin(pathname, buyNow)
      return {
        item_list_id,
        item_list_name: toTitleCase(item_list_id),
      }
    },
  })
}

export default gtm_add_to_cart
