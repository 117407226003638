import type { FC } from 'react'
import type { GetShopItems_getShopItems_lootRewardPackage_lootRewards } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import SmallLootReward from '../../rewards/SmallLootReward'
import { classNames } from '@g4g/utils/src/react/class-names'
import { simplifyCosRewardAmount } from '@g4g/utils/src/numberUtils'
import { RewardType } from '@g4g/graphql/src/shop/__generated__/globalTypes'

const LootRewardRowBadge: FC<{
  lootRewards: GetShopItems_getShopItems_lootRewardPackage_lootRewards[]
  rewardType: RewardType
  gradient?: boolean
  responsive?: boolean
  stretched?: boolean
  quantity?: number
}> = ({ lootRewards, responsive, stretched, gradient, rewardType, quantity }) => {
  const reward = lootRewards.find((l) => l.rewardType === rewardType)

  if (!reward) {
    return <></>
  }

  return (
    <div
      className={classNames(
        'flex items-center justify-center space-x-1',
        gradient && 'bg-gradient-to-r from-black/60 via-black/20 to-transparent',
        stretched && 'md:rounded-bl-lg'
      )}
    >
      <SmallLootReward loot={reward} responsive={responsive} />
      <div
        className={classNames(
          responsive ? 'text-sm lg:text-lg' : 'text-sm',
          ' text-white font-poet cos-standard-text-shadow'
        )}
      >{`+${simplifyCosRewardAmount(reward.amount * (quantity || 1))}`}</div>
    </div>
  )
}

export default LootRewardRowBadge
