import type { FC } from 'react'
import type { GetShopItems_getShopItems } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import { getShopItemResetPeriod } from '@g4g/utils/src/gameDataUtils'
import PeriodicResetBadge from './PeriodicResetBadge'
import LimitBadge from './LimitBadge'
import UnavailableBadge from './UnavailableBadge'
import { isBundle, isLimitReached, verificationRequired } from '@/shop/src/utils/shopItemUtils'

// Top to bottom priority for badges, e.g.
// if both unavailable and weekly reset then only show the
// unavailable badge
const LeftBadges: FC<{ shopItem: GetShopItems_getShopItems; large?: boolean }> = ({
  shopItem,
  large,
}) => {
  // Wether the deal is available for purchase, e.g.
  // TTTT Voucher is not available when user did not verify with phone
  if (verificationRequired(shopItem)) {
    return (
      <UnavailableBadge skew large={large}>
        Verification required
      </UnavailableBadge>
    )
  }

  // Reached all time limit for a deal or highest
  // subscription tier active
  if (isLimitReached(shopItem) && !isBundle(shopItem)) {
    return <LimitBadge large={large} shopItem={shopItem} />
  }

  const resetPeriod = getShopItemResetPeriod(shopItem.shopItemId)
  // Time left for the weekly skin
  if (resetPeriod) {
    return <PeriodicResetBadge period={resetPeriod} large={large} />
  }

  return <></>
}

const ShopItemBadges: FC<{ shopItem: GetShopItems_getShopItems; large?: boolean }> = ({
  shopItem,
  large,
}) => <LeftBadges shopItem={shopItem} large={large} />

export default ShopItemBadges
