import Router, { useRouter } from 'next/router'
import { FC, ReactNode, useEffect } from 'react'
import createReducerContext from '@g4g/utils/src/react/createReducerContext'
import { DealFiltersAction, DealFiltersState } from './provider/types'
import {
  loadStateFromQuery,
  nativeFilterRouterReplace,
  updateQueryParamFilters,
} from './provider/url'
import { updateFilters } from './provider/util/updateFilters'
import { useShopSearch } from '../shared/search/ShopSearchProvider'
import equal from 'fast-deep-equal/es6'

const initialState: DealFiltersState = {
  categories: new Set<string>(),
  resources: new Set<string>(),
  priceMin: 0,
  priceMax: 100,
  // The default sort by the API sort index
  sortBy: 'featured',
}

const reducer = (state: DealFiltersState, action: DealFiltersAction): DealFiltersState => {
  const { categories, resources, priceMax, priceMin } = state
  // Router.replace needs a copy, leaving some query
  // state behind
  const query = process.env.NODE_ENV === 'development' ? { ...Router.query } : Router.query

  // Only load doesn't update the URL
  if (action.type !== 'load-query-filters') {
    updateQueryParamFilters(action, query)
    // Must use native replace, see
    // https://github.com/vercel/next.js/discussions/18072
    // but only in prod cause window.history.replace
    // causing issues with next dev fast refresh
    if (process.env.NODE_ENV === 'development') {
      Router.replace({ pathname: Router.pathname, query })
    } else {
      nativeFilterRouterReplace(Router.pathname, query)
    }
  }

  switch (action.type) {
    case 'update-tick-trait':
      if (state.categories.has(action.trait) || state.resources.has(action.trait)) {
        return state
      }
      switch (action.trait) {
        case 'category':
          return {
            ...state,
            categories: updateFilters(categories, action.name, action.active),
          }
        case 'resource':
          return {
            ...state,
            resources: updateFilters(resources, action.name, action.active),
          }
        default:
          throw new Error(`Unexpected deal filters tick trait: ${JSON.stringify(action)}`)
      }
    case 'update-min-price':
      return priceMin === state.priceMin ? state : { ...state, priceMin }
    case 'update-max-price':
      return priceMax === state.priceMax ? state : { ...state, priceMax }
    case 'update-sort':
      return { ...state, sortBy: action.sortBy }
    case 'reset-all-filters':
      return { ...initialState, categories: new Set<string>(), resources: new Set<string>() }
    case 'load-query-filters':
      return equal(state, loadStateFromQuery(query)) ? state : loadStateFromQuery(query)
    default:
      throw new Error(`Unexpected deal filters reducer action: ${JSON.stringify(action)}`)
  }
}

const [useDealFilters, useDealFiltersOpsDispatch, ReducerProvider] = createReducerContext(
  reducer,
  initialState,
  'DealFiltersProvider'
)

const FilterLoader: FC<{ children?: ReactNode }> = ({ children }) => {
  // useRouter -> query, first Render is undefined
  // we need to wait till the router query is ready & load
  // filters from the url after that
  // https://github.com/vercel/next.js/discussions/11484
  const { isReady } = useRouter()
  const dispatch = useDealFiltersOpsDispatch()
  const { filter } = useShopSearch()

  useEffect(() => {
    if (filter) {
      dispatch({ type: 'update-tick-trait', trait: filter.trait, name: filter.name, active: true })
    }
  }, [filter, dispatch])

  useEffect(() => {
    if (isReady) {
      dispatch({ type: 'load-query-filters' })
    }
  }, [isReady, dispatch])

  return <>{children}</>
}

const DealFiltersProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <ReducerProvider>
      <FilterLoader>{children}</FilterLoader>
    </ReducerProvider>
  )
}

export { useDealFilters, useDealFiltersOpsDispatch }
export default DealFiltersProvider
