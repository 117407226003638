import { classNames } from '@g4g/utils/src/react/class-names'
import React from 'react'

interface IThumbnailInfoPanelProps {
  topLeft: React.ReactNode
  parentClass?: string
  bottomLeft?: React.ReactNode
  stretched?: boolean
}

const ThumbnailInfoPanel: React.FC<IThumbnailInfoPanelProps> = (props) => {
  return (
    <div
      className={classNames(
        props.parentClass,
        props.stretched ? 'w-full h-full rounded-b-lg md:rounded-b-none' : 'rounded-b-lg',
        `px-2 lg:px-3 pt-1 md:pt-2 pb-2 md:pb-3 bg-[#393c45]`
      )}
    >
      <div className="flex pb-1">{props.topLeft}</div>
      <div className="flex">{props.bottomLeft}</div>
    </div>
  )
}

export default ThumbnailInfoPanel
