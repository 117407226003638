import type { FC, ReactNode } from 'react'
import type { GetShopItems_getShopItems } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import Link from 'next/link'

const ShopItemLink: FC<{
  kebabOrShopItem: GetShopItems_getShopItems | string
  'data-cy'?: string
  children?: ReactNode
  className?: string
}> = ({ kebabOrShopItem, className, children, 'data-cy': dataCy }) => (
  <Link
    href={`/deals/${
      typeof kebabOrShopItem === 'string' ? kebabOrShopItem : kebabOrShopItem.kebabName
    }`}
    data-cy={dataCy}
    className={className}
  >
    {children}
  </Link>
)

export default ShopItemLink
