import type { FC } from 'react'
import Badge from '@g4g/ui/src/components/Badge'
import { classNames } from '@g4g/utils/src/react/class-names'
import type { GetShopItems_getShopItems } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import { isSubscription } from '@/shop/src/utils/shopItemUtils'

const LimitBadge: FC<{
  large?: boolean
  shopItem: GetShopItems_getShopItems
  heading?: boolean
}> = ({ heading, large, shopItem }) => (
  <Badge
    skew={!heading}
    data-cy="shop-deals-limit-badge"
    color={isSubscription(shopItem) ? 'green' : 'gray'}
    wrapperClassNames={classNames(
      !heading && 'absolute -top-2 -left-2 z-10 font-bold',
      large && 'text-base lg:text-lg',
      heading && 'text-xs',
      !large && !heading && 'text-sm'
    )}
    classNames={classNames(heading ? 'px-1 py-0.5' : 'py-1')}
  >
    {isSubscription(shopItem) ? 'Highest tier active' : 'Limit reached'}
  </Badge>
)

export default LimitBadge
