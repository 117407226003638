import { useRouter } from 'next/router'
import type { ButtonHTMLAttributes, FC } from 'react'
import { useShoppingCartOpsDispatch } from '../ShoppingCartProvider'
import {
  useAddToCartConfirmation,
  useAddToCartConfirmationOpsDispatch,
} from '../confirmation/AddToCartConfirmationProvider'
import type { ReplaceImpact, ShoppingCartItem } from '../provider/types'
import { Price } from '../hooks/useCalculatedPrices'
import gtm_add_to_cart from '@/shop/src/utils/gtm/gtm_add_to_cart'
import castArray from '@g4g/utils/src/castArray'

/**
 * Plain button that does a dispatch on click.
 * Call `e.preventDefault()` in `onClick` to not redirect.
 */
const AddToCartButton: FC<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    products: ShoppingCartItem | ShoppingCartItem[]
    replace?: ReplaceImpact
    prices?: Price
    buyNow?: boolean
  }
> = ({ children, products, replace, prices, onClick, buyNow, ...buttonProps }) => {
  const { persistedQueryStr } = useAddToCartConfirmation()
  const router = useRouter()
  const dispatchCart = useShoppingCartOpsDispatch()
  const dispatchConfirmation = useAddToCartConfirmationOpsDispatch()
  return (
    <button
      onClick={(e) => {
        onClick?.(e)
        gtm_add_to_cart({ items: castArray(products), buyNow, pathname: router.pathname })
        dispatchConfirmation({
          type: 'push-to-cart',
          prices,
        })
        dispatchCart({
          type: 'add-to-cart',
          items: products,
          replace,
        })
        if (!e.defaultPrevented) {
          const dealsQueryStr = persistedQueryStr ? `?${persistedQueryStr}` : ''
          const urlToGoto = buyNow ? '/checkout' : '/deals' + dealsQueryStr
          router.push(urlToGoto, undefined, { shallow: true })
        }
      }}
      data-cy="shop-add-to-cart-button"
      {...buttonProps}
    >
      {children}
    </button>
  )
}

export default AddToCartButton
