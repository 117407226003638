import type { FC } from 'react'
import { GetShopItems_getShopItems } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import StandardSquareThumbnail from './StandardSquareThumbnail'
import { classNames } from '@g4g/utils/src/react/class-names'
import { useShoppingCart } from '../../../shopping-cart/ShoppingCartProvider'
import LazyBoundary from '@g4g/ui/src/components/LazyBoundary'

const StandardDealsList: FC<{ items: (GetShopItems_getShopItems | undefined)[] }> = ({ items }) => {
  const cartItems = useShoppingCart()

  return (
    <>
      {items.map((item, i) => (
        <LazyBoundary
          data-cy="shop-deals-square-thumbnail-lazy-wrap"
          data-cy-spec={item?.shopItemId}
          rootMargin={'200%'}
          className={classNames(
            'h-[320px] md:h-[360px]',
            'w-full md:w-1/2 xl:w-1/3',
            'md:odd:pr-2 md:even:pl-2 md:pb-4',
            'xl:pl-6 xl:even:pl-6 xl:odd:pr-0 xl:pb-6'
          )}
          key={item?.shopItemId || i}
        >
          <StandardSquareThumbnail
            loading={item === undefined}
            // Cannot use `cartContainsItem` here because bundles/subs only have the
            // first subitem in this list.
            addedToCart={item && cartItems.some((v) => v?.shopItemId === item.shopItemId)}
            shopItem={item}
          />
        </LazyBoundary>
      ))}
    </>
  )
}

export default StandardDealsList
