import type { FC } from 'react'
import IDealThumbnailProps from '../thumbnailProps'
import { classNames } from '@g4g/utils/src/react/class-names'
import ShopItemImage from '../../ShopItemImage'
import { resolveBgCardBlurDataURL } from '@/shop/src/utils/img/shopItemAssets'
import { getShopItemResetPeriod, shopItemWithSkin } from '@g4g/utils/src/gameDataUtils'
import ShopItemBadges from '../badges/ShopItemBadges'
import { isBundle, isLimitReached, verificationRequired } from '@/shop/src/utils/shopItemUtils'
import ShopItemLink from '../../ShopItemLink'

interface ISquareThumbnailProps extends IDealThumbnailProps {
  iconSize: number
  dealThumbnailLoot: React.ReactNode
  thumbnailInfoPanel: React.ReactNode
  shopItemIconClassName?: string
}

const calculateSkinDimensions = (shopItemId: string, stretched?: boolean) => {
  const sizeMultiplier = 0.8
  const skinPeriod = getShopItemResetPeriod(shopItemId)

  if (skinPeriod === 'weekly' || (skinPeriod === 'monthly' && stretched)) {
    return { h: 0.8 * sizeMultiplier, w: 0.55 * sizeMultiplier, skinPeriod }
  }

  if (skinPeriod === 'monthly') {
    return { h: sizeMultiplier, w: sizeMultiplier, skinPeriod }
  }

  return { h: 1, w: 1 }
}

const SquareThumbnail: FC<ISquareThumbnailProps> = (props) => {
  if (!props.shopItem) {
    return null
  }

  const isSkinModel = shopItemWithSkin(props.shopItem.shopItemId)
  const { h, w, skinPeriod } = calculateSkinDimensions(props.shopItem.shopItemId, props.stretched)
  const dimmed =
    !isBundle(props.shopItem) &&
    (isLimitReached(props.shopItem) || verificationRequired(props.shopItem))

  return (
    <div
      data-cy="shop-deals-square-thumbnail"
      data-cy-spec={props.shopItem.shopItemId}
      // Needs to have relative and z-index to move it to a different
      // stacking context for fixing the repainting issues in this case
      // https://dzhavat.github.io/2021/02/18/debugging-layout-repaint-issues-triggered-by-css-transition.html
      // https://stackoverflow.com/questions/49132327/why-does-transform-with-transition-cause-the-whole-page-to-repaint
      className={classNames(props.parentClassName, 'flex flex-col w-full')}
    >
      <ShopItemLink kebabOrShopItem={props.shopItem} className="cursor-pointer">
        {/* group to fix the doom flicker https://www.joshwcomeau.com/animation/css-transitions/#doom-flicker */}
        <div className="group">
          <div className="relative z-10 flex flex-col md:transition-transform grow md:group-hover:-translate-y-2 md:will-change-transform">
            <ShopItemBadges shopItem={props.shopItem} large={props.large} />
            <div
              // Use hex bg color instead of bg-opacity on elements with a hover effect
              // issue: https://stackoverflow.com/questions/12980153/image-moves-on-hover-chrome-opacity-issue
              // can convert with: https://borderleft.com/toolbox/rgba/
              style={{ backgroundColor: '#2f333b', borderColor: '#45474f' }}
              className={classNames(
                'relative w-full border shadow-lg grow',
                dimmed && 'opacity-50',
                props.stretched ? 'flex flex-row rounded-t-lg md:rounded-lg' : 'rounded-t-lg'
              )}
            >
              {/* Deal thumbnail */}
              <div
                className={classNames(
                  `w-full flex relative`,
                  skinPeriod === 'monthly' && 'items-center',
                  props.stretched
                    ? 'justify-center md:justify-start overflow-hidden px-2'
                    : 'justify-center',
                  props.large && props.stretched && 'h-[300px] md:h-[250px]',
                  !props.large && props.stretched && 'h-64 md:h-[200px]',
                  props.large && !props.stretched && 'h-[300px] lg:h-[360px]',
                  !props.large && !props.stretched && 'h-64',
                  'overflow-hidden'
                )}
              >
                {/* Deal background */}
                <ShopItemImage
                  shopItem={props.shopItem}
                  type="bg-card"
                  className={classNames(
                    props.stretched
                      ? 'rounded-t-lg md:rounded-l-lg md:!rounded-r-none'
                      : 'rounded-t-lg',
                    'opacity-80',
                    'object-cover object-center'
                  )}
                  fill
                  sizes="33vw"
                  placeholder="blur"
                  blurDataURL={resolveBgCardBlurDataURL(
                    props.shopItem.shopItemId,
                    props.shopItem.assets?.bgCard
                  )}
                />
                {/* Deal front Image */}
                <ShopItemImage
                  shopItem={props.shopItem}
                  type="front"
                  className={classNames(
                    props.shopItemIconClassName,
                    'h-max z-1',
                    !isSkinModel &&
                      `[mask-image:linear-gradient(to_top,transparent,transparent,#2e323b,#2e323b,#2e323b)]`
                  )}
                  height={props.iconSize * h}
                  width={props.iconSize * w}
                />
              </div>
              {/* Loot boxes (gems/crypton/etc.) */}
              {props.dealThumbnailLoot}
              <>
                {props.stretched && (
                  <div className="hidden w-2/5 md:flex bg-[#393c45]">
                    {props.thumbnailInfoPanel}
                  </div>
                )}
              </>
            </div>
            {/* Deal name and price */}
            {props.stretched ? (
              <div className={classNames(dimmed && 'opacity-50', 'block w-full md:hidden')}>
                {props.thumbnailInfoPanel}
              </div>
            ) : (
              <div className={classNames(dimmed && 'opacity-50')}>{props.thumbnailInfoPanel}</div>
            )}
          </div>
        </div>
      </ShopItemLink>
    </div>
  )
}

export default SquareThumbnail
