import { tickFilters } from '@g4g/utils/src/gameDataUtils'

const { category, resource } = tickFilters()

export const isFilterAvailable = (name: string) =>
  [...category, ...resource].find((c) => c.id === name)

export const updateFilters = (
  filters: Set<string>,
  name: string,
  active?: boolean
): Set<string> => {
  const res = new Set(filters)

  if (!isFilterAvailable(name)) {
    return res
  }

  if (!active) {
    res.delete(name)
    return res
  }

  return res.add(name)
}
