import type { FC } from 'react'
import LootShineProvider from '../shared/rewards/LootShineProvider'
import { classNames } from '@g4g/utils/src/react/class-names'
import LgBreakpoint from '@g4g/ui/src/utility/LgBreakpoint'

const ShopPageLayout: FC<{
  containerClass?: string
  leftSideContent: JSX.Element
  rightSideContent: JSX.Element
}> = ({ leftSideContent, rightSideContent, containerClass }) => (
  <LootShineProvider magicNumber={15}>
    <div className={classNames('container flex', containerClass)}>
      {/* This is to have the side filters only on desktop, so we don't get
        duplicate elements with the mobile filters. Keep the parent div as 
        is to avoid layout shifts. */}
      <div className="sr-only lg:not-sr-only lg:mr-5 lg:w-1/5">
        <LgBreakpoint min={leftSideContent} />
      </div>
      <div className="w-full mx-auto lg:w-4/5">{rightSideContent}</div>
    </div>
  </LootShineProvider>
)

export default ShopPageLayout
