import type { FC } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { getNextWeeklyReset, getFirstDayOfNextMonth } from '@g4g/utils/src/timeUtils'
import { ClockIcon } from '@heroicons/react/24/outline'
import Badge from '@g4g/ui/src/components/Badge'
import { classNames } from '@g4g/utils/src/react/class-names'

dayjs.extend(relativeTime)

const PeriodicResetBadge: FC<{ period: 'weekly' | 'monthly'; large?: boolean }> = ({
  large,
  period,
}) => (
  <Badge
    skew
    wrapperClassNames={classNames(
      'absolute -top-2 -left-2 z-10 font-bold',
      large ? 'text-base lg:text-lg' : 'text-sm'
    )}
    classNames="py-1"
    icon={<ClockIcon className={classNames(large ? 'w-4 h-4 lg:w-5 lg:h-5' : 'w-4 h-4')} />}
  >
    {dayjs(period === 'weekly' ? getNextWeeklyReset() : getFirstDayOfNextMonth()).fromNow(true) +
      ' left'}
  </Badge>
)

export default PeriodicResetBadge
