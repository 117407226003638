import type { FC } from 'react'
import IDealThumbnailProps from '../thumbnailProps'
import DealThumbnailLoot from '../DealThumbnailLoot'
import SquareThumbnail from './SquareThumbnail'
import ThumbnailInfoPanel from '../ThumbnailInfoPanel'
import { classNames } from '@g4g/utils/src/react/class-names'
import { PurchaseCategory } from '@g4g/graphql/src/shop/__generated__/globalTypes'
import PrpsDubiPrice from '../../PrpsDubiPrice'
import AddedToCartIcon from '../../AddedToCartIcon'

const StandardSquareThumbnail: FC<IDealThumbnailProps> = (props) => {
  const { shopItem } = props
  let thumbnailInfoPanel
  if (shopItem) {
    const prices =
      shopItem.purchaseCategory === PurchaseCategory.Bundle &&
      shopItem.lootRewardPackage.bundlePrice?.price
        ? shopItem.lootRewardPackage.bundlePrice
        : shopItem.lootRewardPackage.productPrice
    thumbnailInfoPanel = (
      <ThumbnailInfoPanel
        stretched={props.stretched}
        topLeft={
          <div className="flex items-center justify-between w-full">
            <h4 className="text-white font-poppins sm:text-lg md:text-xl limitLines limitOneLine">
              {shopItem.name}
            </h4>
            {props.addedToCart && <AddedToCartIcon iconsClassNames="w-4 h-4" />}
          </div>
        }
        bottomLeft={
          <div className="flex items-center">
            <PrpsDubiPrice
              crossFade
              textClassSize="text-sm"
              prices={prices}
              discountPercentClass="text-xs"
              showDiscountPercent={true}
              discount={shopItem.discount}
              tokenLogoClass="w-6 h-6"
            />
          </div>
        }
      />
    )
  }

  return (
    <SquareThumbnail
      {...props}
      iconSize={225}
      dealThumbnailLoot={
        <>
          {shopItem?.lootRewardPackage?.lootRewards && (
            <div
              className={classNames(
                props.stretched &&
                  'md:right-1/4 md:bottom-auto md:h-full md:items-center md:pr-14 xl:pr-32',
                props.stretched ? 'justify-center md:justify-end' : 'justify-center',
                'absolute left-0 bottom-0 right-0 flex'
              )}
            >
              <DealThumbnailLoot
                stretched={props.stretched}
                lootRewards={shopItem.lootRewardPackage.lootRewards}
              />
            </div>
          )}
        </>
      }
      thumbnailInfoPanel={thumbnailInfoPanel}
    />
  )
}

export default StandardSquareThumbnail
