import type { FC } from 'react'
import Image from 'next/image'
import { lootRewardIcon } from '@g4g/utils/src/gameDataUtils'
import type { GetShopItems_getShopItems_lootRewardPackage_lootRewards } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import { classNames } from '@g4g/utils/src/react/class-names'
import { RewardType } from '@g4g/graphql/src/shop/__generated__/globalTypes'
import standard_container_sm from '@/shop/public/shared-assets/img/resources/standard_container_sm.png'

const SmallLootRewardImage: FC<{
  name: string
  size?: number
  className?: string
  upperSize?: string
  alt?: string
}> = ({ size = 24, className, name, alt = '', upperSize }) => (
  <Image
    className={classNames('relative', className)}
    src={`/shared-assets/img/resources/${name}.png`}
    width={size}
    height={size}
    sizes={upperSize}
    alt={alt}
  />
)

const SmallLootRewardIcon: FC<{
  iconName: string
  rewardType: RewardType
  responsive?: boolean
}> = ({ iconName, rewardType, responsive }) => (
  <>
    {responsive ? (
      <SmallLootRewardImage name={iconName} size={40} upperSize="48px" alt={rewardType} />
    ) : (
      <SmallLootRewardImage className="max-w-full h-auto" name={iconName} alt={rewardType} />
    )}
  </>
)

const SmallLootReward: FC<{
  loot: GetShopItems_getShopItems_lootRewardPackage_lootRewards
  responsive?: boolean
  withBackground?: boolean
}> = ({ loot, responsive, withBackground }) => {
  const iconName = lootRewardIcon(loot, true)

  return (
    <>
      {iconName && (
        <div
          className={classNames(
            'relative block items-center justify-center',
            responsive ? 'w-6 h-6 lg:w-10 lg:h-10' : 'w-6 h-6'
          )}
        >
          {withBackground && (
            <Image
              className="absolute top-0 bottom-0 left-0 right-0"
              src={standard_container_sm}
              alt="Loot container background"
            />
          )}
          <SmallLootRewardIcon
            rewardType={loot.rewardType}
            iconName={iconName}
            responsive={responsive}
          />
        </div>
      )}
    </>
  )
}

export default SmallLootReward
