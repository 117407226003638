import type { FC, ReactNode } from 'react'
import Badge from '@g4g/ui/src/components/Badge'
import { classNames } from '@g4g/utils/src/react/class-names'

const UnavailableBadge: FC<{
  large?: boolean
  children?: ReactNode
  right?: boolean
  skew?: boolean
}> = ({ large, children, right, skew }) => (
  <Badge
    skew={skew}
    data-cy="shop-deals-unavailable-badge"
    color="gray"
    wrapperClassNames={classNames(
      'absolute z-10 font-bold',
      large ? 'text-base lg:text-lg' : 'text-sm',
      right ? '-right-3 top-0' : '-top-2 -left-2'
    )}
    classNames={classNames('py-1', !skew && 'px-3')}
  >
    {children}
  </Badge>
)

export default UnavailableBadge
