import type { ButtonHTMLAttributes, FC } from 'react'
import Link from 'next/link'
import {
  ArrowRightIcon,
  ShoppingCartIcon,
  CursorArrowRaysIcon,
  BoltIcon,
} from '@heroicons/react/20/solid'
import AddToCartButton from './AddToCart'
import type { ReplaceImpact, ShoppingCartItem } from '../provider/types'
import Spinner from '@g4g/ui/src/internal/Spinner'
import { classNames } from '@g4g/utils/src/react/class-names'
import { Price } from '../hooks/useCalculatedPrices'

type CartButtonIcons = 'arrow' | 'cart' | 'click' | 'bolt'

const icons: { [k in CartButtonIcons]: React.ReactNode } = {
  arrow: <ArrowRightIcon className="w-4 h-4 text-white" />,
  cart: <ShoppingCartIcon className="w-4 h-4 text-white" />,
  click: <CursorArrowRaysIcon className="w-4 h-4 text-white" />,
  bolt: <BoltIcon className="w-4 h-4 text-white" />,
} as const

const Icon: FC<{ icon?: CartButtonIcons; loading?: boolean }> = ({ icon, loading }) => {
  return (
    <>
      {loading ? (
        <Spinner delay={500} className="w-4 h-4 text-white" />
      ) : (
        <>{icon && icons[icon]}</>
      )}
    </>
  )
}

const CartButton: FC<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    action?: {
      type: 'add'
      products: ShoppingCartItem | ShoppingCartItem[]
      replace?: ReplaceImpact
      prices?: Price
      buyNow?: boolean
    }
    href?: string
    replace?: boolean
    'data-cy'?: string
    color?: 'primary' | 'secondary' | 'tertiary' | 'danger'
    icon?: CartButtonIcons
    loading?: boolean
  }
> = ({
  children,
  href,
  replace,
  'data-cy': dataCy,
  color = 'primary',
  icon,
  action,
  loading,
  ...buttonProps
}) => {
  const btnClasses = classNames(
    buttonProps.className,
    'flex items-center justify-center px-3 font-bold p-2',
    'text-sm text-white rounded-md space-x-1',
    buttonProps.disabled && 'bg-gray-400',
    loading && 'cy-loading',
    loading || buttonProps.disabled ? 'opacity-40' : 'hover:bg-opacity-80',
    color === 'primary' && 'bg-green-800',
    color === 'secondary' && 'bg-gray-600',
    color === 'danger' && 'bg-red-800',
    color === 'tertiary' && 'bg-teal-800'
  )

  return (
    <>
      {action ? (
        <AddToCartButton
          disabled={buttonProps.disabled || loading}
          className={`${btnClasses}${buttonProps.className ? ` ${buttonProps.className}` : ''}`}
          products={action.products}
          replace={action.replace}
          prices={action.prices}
          buyNow={action.buyNow}
        >
          <span>{children}</span>
          <Icon icon={icon} loading={loading} />
        </AddToCartButton>
      ) : (
        <>
          {href && !buttonProps.disabled ? (
            <Link replace={replace} href={href} className={btnClasses} data-cy={dataCy}>
              <span>{children}</span>
              <Icon icon={icon} loading={loading} />
            </Link>
          ) : (
            <button
              {...buttonProps}
              data-cy={dataCy}
              disabled={buttonProps.disabled || loading}
              className={btnClasses}
            >
              <span>{children}</span>
              <Icon icon={icon} loading={loading} />
            </button>
          )}
        </>
      )}
    </>
  )
}

export default CartButton
