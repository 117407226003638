import { useBoolean, useIntersection } from 'react-use'
import { FC, ReactNode, useEffect, useRef } from 'react'

const LazyBoundary: FC<{
  children: ReactNode
  className?: string
  rootMargin?: string
  'data-cy'?: string
  'data-cy-spec'?: string
}> = ({
  children,
  'data-cy': dataCy,
  'data-cy-spec': dataCySpec,
  className = '',
  rootMargin = '1200px',
}) => {
  const ref = useRef(null)
  const x = useIntersection(ref, {
    rootMargin,
  })

  // Only flip to true once, ignore intersections after that.
  const [hasIntersected, toggleVal] = useBoolean(false)
  useEffect(() => {
    if (!hasIntersected && x?.isIntersecting) {
      toggleVal()
    }
  }, [x?.isIntersecting, hasIntersected])

  return (
    <span data-cy={dataCy} data-cy-spec={dataCySpec} className={className} ref={ref}>
      {hasIntersected ? children : null}
    </span>
  )
}

export default LazyBoundary
